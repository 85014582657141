const isAvailableForRent = e => e.status === 'available' && e.name && !e.price

export const groupVideoActions = (actions) => {
  if (!actions.username) {
    return actions.actions
  }
  const availableForRent = actions.actions.filter(isAvailableForRent)
  const otherItems = actions.actions.filter(e => !isAvailableForRent(e))
  return [availableForRent, ...otherItems]
}
