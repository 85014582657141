import $ from 'jquery'
import Swiper from 'swiper/bundle'
import { galleryFancybox } from './fancyboxUtils'

const createNavigation = containerEl => {
  return {
    navigation: {
      nextEl: $('.swiper-button-next', containerEl).get(0),
      prevEl: $('.swiper-button-prev', containerEl).get(0)
    }
  }
}
const createPagination = containerEl => ({
  pagination: {
    el: $('.swiper-pagination', containerEl).get(0),
    type: 'bullets',
    clickable: true
  }
})

const defaultOpts = ($el, containerEl) => {
  let outerContainer
  if (containerEl) {
    outerContainer = $($el).closest('.swiper-outer-container')
  } else {
    outerContainer = $($el)
  }
  return {
    speed: 200,
    ...createNavigation(outerContainer),
    ...createPagination(outerContainer)
  }
}

const createCarousel = ($el, opts) => {
  const { containerEl, ...swiperOpts } = opts
  return new Swiper($el, {
    ...defaultOpts($el, containerEl),
    ...swiperOpts
  })
}

const createRelatedItemsCarousel = $el => {
  const outerContainer = $($el).closest('.swiper-outer-container')
  new Swiper($el, {
    slidesPerView: 1,
    //loop: true,
    spaceBetween: 36,
    mousewheel: { sensitivity: 1, forceToAxis: true },
    //freeMode: true,
    //freeModeSticky: true,
    grabCursor: true,
    ...createNavigation(outerContainer),
    //...createPagination(outerContainer),
    breakpoints: {
      630: { slidesPerGroup: 2, slidesPerView: 2, spaceBetween: 30 },
      768: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 30 },
      1200: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 62 },
      1400: { slidesPerGroup: 4, slidesPerView: 4, spaceBetween: 62 }
    }
  })
}

const createVodCollectionCarousel = $el => {
  const swiper = new Swiper($('.swiper-container', $el).get(0), {
    ...defaultOpts($el),
    mousewheel: { sensitivity: 1, forceToAxis: true },
    grabCursor: true,
    spaceBetween: 8,
    slidesPerView: 2,
    slidesPerGroup: 2,
    //loop: true,
    preloadImages: false,
    lazy: true,
    watchSlidesVisibility: true,
    breakpoints: {
      630: {
        spaceBetween: 30
      },
      1200: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30
      }
    }
  })
  //* initialize fancybox
  galleryFancybox($('[data-gallery]', $el), {
    backFocus: false
  })
  return swiper
}

window.createCarousel = createCarousel
window.createVodCollectionCarousel = createVodCollectionCarousel
window.createRelatedItemsCarousel = createRelatedItemsCarousel
window.Swiper = Swiper
