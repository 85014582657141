import 'htmx.org'
import $ from 'jquery'
import { createModal } from './uiUtils'
import { submitAjaxForm, toggleAjaxLoader } from './ajaxUtils'
import { t } from './i18n.js'
import isArray from 'lodash.isarray'
import { groupVideoActions } from './vod/vodUtils'

const VIDEO_ACTIONS_REFRESH = 1000 * 60

const VOD_RENT_STATUS = {
  available: 'available',
  free: 'free',
  rented: 'rented',
  active: 'active',
  already_rented: 'already_rented',
  available_in: 'available_in'
}

const modalOpts = {
  modalCssClass: 'modal fade modal--player',
  modalDialogCssClass: 'modal-dialog modal-dialog-centered modal-lg'
}

const setupAjaxForm = (modalEl, url, html, footer) => {
  const modalBody = $('.modal-body', modalEl)
  const modalFooter = $('.modal-footer', modalEl)
  modalBody.html(html)
  modalFooter.html(footer)
  htmx.process(modalBody.get(0))
  htmx.process(modalFooter.get(0))
  const $form = $('form', modalEl)
  if (!$form.is('[data-no-ajax]')) {
    $form.on('submit', async event => {
      const $targetEl = $('.modal-content', modalEl)
      toggleAjaxLoader($targetEl)
      const submitUrl = $('form', modalEl).attr('action') || url
      try {
        const result = await submitAjaxForm(submitUrl, event)
        if (result.url) {
          window.location = result.url
        } else {
          setupAjaxForm(modalEl, url, result.html, result.footer)
        }
      } catch (e) {
        $('.modal-body', modalEl).html(`${t('error')}: ${e.statusText}`)
      } finally {
        toggleAjaxLoader($targetEl, false)
      }
    })
  }
}

const vodModalGet = async (modalEl, url) => {
  try {
    const data = await $.getJSON(url)
    // ajax form returns url to redirect user to
    if (data.url) {
      window.location = data.url
      return
    }
    setupAjaxForm(modalEl, url, data.html, data.footer)
  } catch (e) {
    $('.modal-body', modalEl).html('error')
  }
}

const ajaxFormModal = async (options = {}) => {
  const opts = {
    title: '',
    description: '',
    url: null,
    ...options
  }
  const modalEl = createModal(opts.title, opts.description, modalOpts)
  // deletes modal content when hidden, so ie: video would not continue to play hidden
  modalEl.on('hide.bs.modal', () => {
    $('.modal-body', modalEl).html('')
  })
  // get content
  vodModalGet(modalEl, opts.url)
}

const rentModal = async url => {
  await ajaxFormModal({ title: '', url })
}

const availableInModal = async (title, url) => {
  const modalEl = createModal(title, '')
  $.get(url).done(html => {
    const modalBody = $('.modal-body', modalEl)
    modalBody.html(html)
    modalEl.on('shown.bs.modal', () => {
      modalBody.css('height', `${modalBody.innerHeight()}px`)
    })
  })
}

const createButton = (css_class, label, onClick) => {
  const btn = $(`<button class="${css_class}">${label}</button>`)
  btn.on('click', onClick)
  return btn
}

const loginModalForm = (next = undefined) => {
  const ev = new Event('open-login-modal')
  ev.detail = { next }
  window.dispatchEvent(ev)
}

const addVideoActions = (el, actions) => {
  const $el = $(el)
  const actionsData = JSON.stringify(actions)
  if ($el.data('actionsData') === actionsData) {
    return
  }
  $el.data('actionsData', actionsData)
  $el.html('')

  const rentAction = url => {
    // workaround for https://github.com/pennersr/django-allauth/issues/1766
    return actions.username
      ? () => rentModal(url)
      : () => loginModalForm(window.location.pathname + window.location.search)
  }

  groupVideoActions(actions).forEach(item => {
    const css_class = `btn-video-action ${item.css_class || ''}`
    // dropdown for status=available no price
    if (isArray(item)) {
      if (item.length) {
        const dropdownMenu = $(
          '<div class="dropdown-menu tw-border" aria-labelledby="dropdownMenuButton"></div>'
        )
        for (const library of item) {
          dropdownMenu.append(
            createButton('dropdown-item', library.name, rentAction(library.url))
          )
        }
        const dropdown = $(`
        <div class="dropdown">
          <button class="${css_class} dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            ${item[0].action_label}
          </button>
        </div>
        `)
        dropdown.append(dropdownMenu)
        $el.append(dropdown)
      }
    } else if (
      [
        VOD_RENT_STATUS.available,
        VOD_RENT_STATUS.rented,
        VOD_RENT_STATUS.active,
        VOD_RENT_STATUS.free
      ].includes(item.status)
    ) {
      $el.append(
        createButton(css_class, item.action_label, rentAction(item.url))
      )
      if (item.status == VOD_RENT_STATUS.active) {
        $el.append(
          createButton(
            'btn-video-action btn-hero--return',
            item.return_label,
            () => ajaxFormModal({ url: item.return_url })
          )
        )
      }
    } else if (item.status === VOD_RENT_STATUS.available_in) {
      const btn = $(
        `<button class="${css_class}"><span>${item.action_label}</span></button>`
      )
      if (actions.username) {
        // TODO: load modal
        btn.on('click', () => availableInModal(item.title, item.url))
      } else {
        btn.on('click', loginModalForm)
      }
      $el.append(btn)
    } else {
      const btn = $(
        `<button title="${item.rented_until_text}" class="${css_class}"><span>${item.action_label}</span></button>`
      )
      btn.tooltip()
      $el.append(btn)
    }
  })
  if (actions.info) {
    $('.video-info__context', $el.parents('[data-publicity-video]'))
      .html(actions.info)
      .show()
  } else {
    $('.video-info__context', $el.parents('[data-publicity-video]')).hide()
  }
}

const getVideoActions = el => {
  const url = $(el).attr('data-video-actions')
  const xhr = $.ajax({
    beforeSend: function(request) {
      request.setRequestHeader('Accept-Language', window.language)
    },
    dataType: 'json',
    url: url
  })
  if (el.dataset.refresh) {
    window.clearTimeout(el.dataset.refresh)
    delete el.dataset.refresh
  }
  xhr
    .done(actions => {
      addVideoActions(el, actions)
    })
    .always(() => {
      el.dataset.refresh = window.setTimeout(
        () => getVideoActions(el),
        VIDEO_ACTIONS_REFRESH
      )
    })
}

const getAllVideoActions = () => {
  $('[data-video-actions]').each((index, el) => getVideoActions(el))
}
$(function() {
  getAllVideoActions()
})

const RentWatch = url => {
  return {
    available: true,
    getResult: function() {
      if (!this.available) return
      fetch(url)
        .then(response => response.json())
        .then(result => {
          this.available = result
        })
        .finally(() => window.setTimeout(this.getResult.bind(this), 30000))
    },
    init() {
      window.setTimeout(this.getResult.bind(this), 30000)
    }
  }
}

window.RentWatch = RentWatch
window.getAllVideoActions = getAllVideoActions
window.vodModalGet = vodModalGet

window.bsf = {
  ajaxFormModal,
  events: {
    PACKAGE_BUY: 'package-buy',
    RENT_RETURN: 'rent-return',
    RENT_ACTIVATE: 'rent-activate'
  }
}
